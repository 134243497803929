import { render, staticRenderFns } from "./OrderLinesTable.vue?vue&type=template&id=51870c57&scoped=true&"
import script from "./OrderLinesTable.vue?vue&type=script&lang=js&"
export * from "./OrderLinesTable.vue?vue&type=script&lang=js&"
import style0 from "./OrderLinesTable.vue?vue&type=style&index=0&id=51870c57&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51870c57",
  null
  
)

export default component.exports